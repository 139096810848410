.headerContainer {
    margin: var(--spacing--md) var(--spacing--lg);
    display: flex;
    flex-direction: column;
}

.loadingContainer {
    display: flex;
    justify-content: center;
    background-color: var(--color-gray--10);
    padding: 0 0 var(--spacing--md) 0;
}

.spinnerContainer {
    display: inline-flex;
    height: 70px;
    position: relative;
    width: 70px;
}

.statusTabsAndEmptyTabContainer {
    display: flex;
    align-items: stretch;
    margin: auto 0;
    max-width: 1440px;
    border-bottom: var(--border-width) solid var(--color-gray--20);
}

.tabContainer {
    margin: var(--spacing--md) var(--spacing--lg);
}

.tabContentContainer {
    background-color: var(--color-gray--10);
}

.dealsLink {
    height: 20px;
}

@media (min-width: 60rem) {
    .tabContainer {
        margin: var(--spacing--md) 40px;
    }

    .headerContainer {
        margin: var(--spacing--md) 40px;
    }
}

@media (min-width: 90rem) {
    .tabContainer {
        margin: var(--spacing--md) auto;
        max-width: var(--breakpoint-screen--x-lg);
    }

    .headerContainer {
        max-width: var(--breakpoint-screen--x-lg);
        margin: var(--spacing--md) auto;
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .tabsContainer {
        width: 100%
    }

    .tabContainer {
        margin: 0;
        padding: 0;
    }
}

@media only screen and (min-width: 769px) {
    .tabContentContainer {
        background-color: var(--color-white);
    }

    .loadingContainer {
        background-color: var(--color-white);
    }
}

