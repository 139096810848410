.breadCrumb {
    display: flex;
    align-items: center;
}

.breadCrumbElement {
    font-size: var(--font-size--12);
    font-weight: var(--font-weight--regular);
    white-space: nowrap;
}

.breadCrumbElement a {
    color: var(--color-gray--50);
    cursor: pointer;
    text-decoration: none;
}

.breadCrumbElement a:visited {
    color: var(--color-gray--50);
}

.breadCrumbElement:not(:first-child)::before {
    content: '/';
    color: var(--color-gray--30);
    margin: 0 var(--spacing--sm);
}

.breadCrumbElement:last-child {
    color: var(--color-gray--40);
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
}

@media (min-width: 960px) {
    .breadCrumbElement:last-child {
        max-width: 500px;
    }
}
