.couponCardContainer {
    max-width: 100%;
    margin: 0 0 var(--spacing--md) var(--spacing--md);
    display: flex;
    align-items: stretch;
}

@media (max-width: 768px) {
    .couponCardContainer {
        width: 100%;
    }
}
